import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
} from 'styled-system';
import { Icon } from 'react-icons-kit';
import { email } from 'react-icons-kit/entypo/email';
import { network } from 'react-icons-kit/entypo/network';

import { base, themed } from 'reusecore/src/elements/base';
import Box from 'reusecore/src/elements/Box';
import Container from 'common/src/components/UI/Container';
import Heading from 'reusecore/src/elements/Heading';
import Logo from 'reusecore/src/elements/UI/Logo';
import Text from 'reusecore/src/elements/Text';
import FooterWrapper, { List, ListItem, MailLink } from './footer.style';

import LogoImage from 'common/src/assets/image/saas/europa_logo.png';

const TextWrapper = styled('div')(
  base,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  themed('Text')
);

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Footer_Data {
          title
          menuItems {
            text
            url
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="#"
              logoSrc={LogoImage}
              title="Europa Notebooks"
              logoStyle={logoStyle}
              titleStyle={titleStyle}
            />
            <Text content="ABN 52 638 081 571" {...textStyle} />
            <TextWrapper {...textStyle} style={{ marginBottom: 0 }}>
              <Icon icon={email} />{' '}
              <MailLink href="mailto:enquiry@europa-labs.com">
                enquiry(at)europa-labs.com
              </MailLink>
            </TextWrapper>
            <TextWrapper {...textStyle}>
              <Icon icon={network} />{' '}
              <MailLink href="https://www.europa-labs.com">
                www.europa-labs.com
              </MailLink>
            </TextWrapper>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.saasJson.Footer_Data.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      <a href={item.url} className="ListItem">
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    marginBottom: '15px',
    marginTop: '15px',
  },
  // Default logo size
  logoStyle: {
    width: '48px',
    mr: '10px',
    mb: '0px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '14px',
    mb: '10px',
  },
};

export default Footer;
