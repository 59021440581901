import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import NavbarWrapper from 'reusecore/src/elements/Navbar';
import Logo from 'reusecore/src/elements/UI/Logo';
import Box from 'reusecore/src/elements/Box';
import Container from 'common/src/components/UI/Container';

import LogoImage from 'common/src/assets/image/saas/europa_logo.png';

const Navbar2 = ({ navbarStyle, logoStyle, row, menuWrapper, homePath }) => {

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Box {...row}>
          <Logo
            href={homePath}
            logoSrc={LogoImage}
            title="Europa Notebooks"
            logoStyle={logoStyle}
          />
          <div style={{flex: 1}}></div>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar2.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar2.defaultProps = {
  navbarStyle: {
    className: 'hosting_navbar',
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: [
      'space-between',
      'space-between',
      'space-between',
      'flex-start',
    ],
    width: '100%',
  },
  logoStyle: {
    maxWidth: '48px',
    maxHeight: '48px',
    mr: [0, 0, 0, '10px'],
  },
};

export default Navbar2;
