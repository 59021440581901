import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import FeatureSectionWrapper from './featureSection.style';

const MoreDetailsSection = ({
  row,
  col,
  col2,
  sectionHeader,
  sectionTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Features {
          id
          title
          description
          icon
        }
      }
    }
  `);

  const detailsList = (
    <ul>
      <li>Edit and create Jupyter Notebooks using our enhanced user interface or the classic Jupyter interface</li>
      <li>Publish read-only notebooks</li>
      <li>User interface enhancements such as cell drag-and-drop, data explorer, and code hiding for publishing</li>
      <li>Collaborations features such as commenting on published notebooks</li>
      <li>Data explorer for datasets, and in notebooks to visualize outputs</li>
      <li>Organize notebooks and datasets into projects</li>
      <li>Easily import and export notebooks and datasets</li>
    </ul>
  );

  return (
    <FeatureSectionWrapper id="service_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col2} key={0}>
            <FeatureBlock
              wrapperStyle={blockWrapperStyle}
              contentStyle={contentStyle}
              title={<Heading content={'More details'} {...featureTitle} />}
              description={
                <Text content={detailsList} {...featureDescription} />
              }
              className="saasFeature"
            />
          </Box>
          <Box className="col" {...col} key={1}>
            <FeatureBlock
              wrapperStyle={blockWrapperStyle}
              contentStyle={contentStyle}
              title={<Heading content={'Media'} {...featureTitle} />}
              className="saasFeature"
            />
          </Box>
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// MoreDetailsSection style props
MoreDetailsSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// MoreDetailsSection default style
MoreDetailsSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '40px', '40px', '80px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 3, 1 / 3],
  },
  col2: {
    width: [1, 2 / 3, 2 / 3, 2 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '75px', '75px', '84px'],
    height: ['70px', '75px', '75px', '84px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: ['20px', '20px', '20px', '30px'],
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: ['10px', '10px', '10px', '20px'],
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
};

export default MoreDetailsSection;
